import {Component, OnInit} from '@angular/core';
import { ROUTES } from './side-nav-routes.config';
import { ThemeConstantService } from '../../services/theme-constant.service';
import { Router } from '@angular/router';
import { AuthService } from '../../services/authentication.service';

@Component({
    selector: 'app-sidenav',
    templateUrl: './side-nav.component.html'
})

export class SideNavComponent implements OnInit {

    public menuItems: any[];
    isFolded: boolean;
    isSideNavDark: boolean;
    isExpand: boolean;
    authStatus: boolean;
    constructor( private themeService: ThemeConstantService,
                 private authService: AuthService,
                 private router: Router) {}

    ngOnInit(): void {
        this.authStatus = this.authService.isAuth;
        this.menuItems = ROUTES.filter(menuItem => menuItem);
        this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
        this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);
        this.themeService.isSideNavDarkChanges.subscribe(isDark => this.isSideNavDark = isDark);
    }

    closeMobileMenu(): void {
        if (window.innerWidth < 992) {
            this.isFolded = false;
            this.isExpand = !this.isExpand;
            this.themeService.toggleExpand(this.isExpand);
            this.themeService.toggleFold(this.isFolded);
        }
    }

    logout(): void{
      this.authService.logout();
      this.authStatus = this.authService.isAuth;
      this.router.navigate(['/']);
    }
}
