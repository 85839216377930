import { SideNavInterface } from '../../interfaces/side-nav.type';
export const ROUTES: SideNavInterface[] = [
    {
        path: 'dashboard',
        title: 'Dashboard',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'dashboard',
        submenu: []
    },
    {
      path: 'trainers',
      title: 'Instructeurs',
      iconType: 'nzIcon',
      iconTheme: 'outline',
      icon: 'team',
      submenu: []
    },
    {
      path: 'contact',
      title: 'Contact',
      iconType: 'nzIcon',
      iconTheme: 'outline',
      icon: 'info',
      submenu: []
    },
    {
      path: 'blog',
      title: 'Blog',
      iconType: 'nzIcon',
      iconTheme: 'outline',
      icon: 'book',
      submenu: []
    }
];
