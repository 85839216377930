import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { FullLayoutComponent } from './layouts/full-layout/full-layout.component';
import { CommonLayoutComponent } from './layouts/common-layout/common-layout.component';

const appRoutes: Routes = [
    {
        path: '',
        component: FullLayoutComponent,
        loadChildren: () => import('./features/guest/guest.module').then(mod => mod.GuestModule)
    },
    {
      path: 'auth',
      component: CommonLayoutComponent,
      loadChildren: () => import('./features/auth/auth.module').then(mod => mod.AuthModule)
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes, {
            anchorScrolling: 'enabled',
            scrollPositionRestoration: 'enabled'
        })
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule {}
