import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { NgChartjsModule } from 'ng-chartjs';

import { ThemeConstantService } from './services/theme-constant.service';
import { SearchPipe } from './pipes/search.pipe';

@NgModule({
  exports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    PerfectScrollbarModule,
    SearchPipe,
    NgChartjsModule,
    FormsModule,
    ReactiveFormsModule,
  ],
    imports: [
        RouterModule,
        CommonModule,
        PerfectScrollbarModule,
        NgChartjsModule,
        FormsModule,
        ReactiveFormsModule
    ],
    declarations: [
        SearchPipe
    ],
    providers: [
        ThemeConstantService
    ]
})

export class SharedModule { }
